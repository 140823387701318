:root {
  --nav-starfall-duration: 1s;
}

#navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: 10%;
  border-bottom: 1px rgba(255, 255, 255, 0.2) solid;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
}

.nav-link,
.nav-active {
  font-size: 2em;
  color: transparent;
  text-shadow: 0 0 0.25px white;
  transition: text-shadow 0.65s ease-out;
}

.nav-link:hover,
.nav-active {
  text-shadow: 0px 0px 0px lightskyblue;
}

.nav-active {
  text-decoration: none;
  box-shadow: 0 1px 0 lightskyblue;
  /* transition: box-shadow 0.65s ease-out; */
}

.nav-items {
  justify-content: flex-end;
}

.menu {
  box-sizing: border-box;
  padding: 5px;
  list-style-type: none;
  text-decoration: none;
}
.nav-header {
  animation: starfall var(--nav-starfall-duration) linear;
  animation-iteration-count: 1;
  margin-left: 25px;
  margin-right: 5px;
}
.nav-item {
  animation: starfall var(--nav-starfall-duration) linear;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-delay: 0.25s;
  margin-left: 5px;
  margin-right: 25px;
  font-size: 0.7em;
}

@media (max-width: 480px) {
  /* Adjust nav item sizes/formatting */
  .nav-link,
  .nav-active {
    font-size: 1.75em;
  }
  .nav-item {
    margin-right: 15px;
  }
}
@media (max-width: 420px) {
  /* Adjust nav item sizes/formatting */
  .nav-link,
  .nav-active {
    font-size: 1.5em;
  }
  .nav-item {
    margin-right: 10px;
  }
}
@media (max-width: 360px) {
  .nav-link,
  .nav-active {
    font-size: 1.25em;
  }
  .nav-item {
    margin-right: 5px;
  }
}
