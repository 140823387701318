:root {
  --Contact-transition: 0.5s;
  --Contact-blur: 0.75px;
  --Contact-blur-off: 0.2px;
  --Contact-animation-delay: 0.75s;
}

.Contact {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
@media (min-width: 769px) {
  section.Contact::before {
    content: '';
    position: absolute;
    animation: ripple-l 4s linear forwards;
    animation-iteration-count: infinite;
    animation-delay: 0.65s;
    opacity: 0;
    background-color: transparent;
    border: 2px rgba(255, 255, 255, 0.1) solid;
    border-radius: 100%;
  }
}

.contact-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ContactLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
  margin-top: 10px;
  padding-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.resume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.contact,
.resume {
  /* font-family: 'Shadows Into Light', cursive; */
  font-weight: bold;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.1);
  transition: text-shadow var(--Contact-transition) linear,
    color var(--Contact-transition) linear;
}

.contact {
  cursor: default;
  text-shadow: 0 0 var(--Contact-blur) white;
}

.resume:hover,
.contact:hover {
  text-shadow: 0 0 var(--Contact-blur-off) lightskyblue;
  color: lightskyblue;
}

.link-image {
  filter: blur(var(--Contact-blur));
  transition: filter var(--Contact-transition) linear;
}
.ContactLink:hover > .link-image {
  filter: blur(var(--Contact-blur-off));
}

.contact-text {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.1);
  text-shadow: 0 0 var(--Contact-blur) white;
  transition: text-shadow var(--Contact-transition) linear,
    color var(--Contact-transition), linear;
  margin: 2px;
  visibility: hidden;
}

.ContactLink:hover > .contact-text {
  text-shadow: 0 0 var(--Contact-blur-off) lightskyblue;
  visibility: visible;
  color: lightskyblue;
}

.link-image,
.contact-text,
.contact,
.resume {
  animation: starfall 2s linear;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.contact {
  animation-delay: calc(var(--Contact-animation-delay) - 0.25s);
}

.link-image,
.contact-text {
  animation-delay: var(--Contact-animation-delay);
}
.resume {
  animation-delay: calc(var(--Contact-animation-delay) + 0.25s);
  text-shadow: 0 0 var(--Contact-blur) white;
  box-shadow: 0 2px 0 rgba(255, 255, 255, 0.5);
}

.contact,
.resume {
  font-size: 1.25em;
}

.contact-header {
  font-size: 2em;
}
.resume-pdf {
  width: 70%;
}
