:root {
  --Portfolio-transition: 0.5s;
  --Portfolio-blur: 0.25px;
}

/* Portfolio Card and container styling */
.portfolio-container {
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;
  height: 100%;
}

.Portfolio {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3.5%;
  margin-bottom: 3.5%;
}

.Portfolio.r1,
.Portfolio.r3 {
  width: 33%;
}
.Portfolio.r2 {
  width: 50%;
}

.PortfolioCard {
  --PortfolioSize: 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0.8;
  width: var(--PortfolioSize);
  height: var(--PortfolioSize);
  margin: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 5%;
  transition: opacity var(--Portfolio-transition) linear,
    box-shadow var(--Portfolio-transition) ease-out;
}
.PortfolioCard:hover {
  opacity: 1;
  box-shadow: 0 0 35px rgba(255, 255, 255, 0.5);
}

.PortfolioCard > figcaption {
  transform: rotate(-45deg);
  opacity: 1;
}

.portfolio-bg {
  pointer-events: none;
  position: absolute;
  left: -25%;
  top: -25%;
  width: 150%;
  height: 150%;
  opacity: 0.5;
  transform: rotate(-45deg);
  transition: opacity var(--Portfolio-transition) ease-out;
}

.PortfolioCard:hover > .portfolio-bg {
  opacity: 0.75;
}

/* .portfolio-title {
  text-shadow: -1px 0 var(--Portfolio-blur) black,
    0 1px var(--Portfolio-blur) black, 1px 0 var(--Portfolio-blur) black,
    0 -1px var(--Portfolio-blur) black;
  font-size: 2em;
} */

.pf-rotate {
  transform: rotate(45deg);
}
/* Portfolio Item Styling */

.PortfolioItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 25px;
  box-shadow: 0 0 25px white;
  width: 50%;
  height: 75%;
  padding: 20px;
  animation: faded-starfall 1s ease-in backwards;
  animation-delay: 0.15s;
}

.PortfolioItem > div {
  margin-top: 4%;
  margin-bottom: 4%;
}

.portfolio-item-content {
  display: flex;
  flex-direction: row;
}

.portfolio-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 0;
}

.portfolio-title {
  text-decoration: none;
  color: skyblue;
}

.portfolio-item-text {
  text-decoration: none;
  text-shadow: 2px 2px 8px steelblue;
  color: rgba(255, 255, 255, 0.75);
}

.gh-link {
  text-shadow: 0 0 var(--Portfolio-blur) skyblue;
  text-decoration: none;
  color: skyblue;
  cursor: default;
}

.portfolio-image {
  overflow: hidden;
  width: 50%;
  /* height: 20%; */
  /* box-shadow: 0px 0px 25px white; */
}

a > .portfolio-link > .portfolio-item-text {
  margin-right: 2ch;
  right: 0;
}
a > .portfolio-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.portfolio-image > a > video {
  border-radius: 10px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.portfolio-image > a > image {
  border-radius: 10px;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.portfolio-description {
  width: 35%;
  color: rgba(255, 255, 255, 0.9);
  text-align: left;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 10%;
  /* align-items: center; */
  /* text-shadow: -1px 0 var(--Portfolio-blur) black,
    0 1px var(--Portfolio-blur) black, 1px 0 var(--Portfolio-blur) black,
    0 -1px var(--Portfolio-blur) black; */
}
.portfolio-description-text {
  margin-top: 0;
  margin-bottom: 5px;
}

@media (max-width: 440px) {
  .PortfolioCard {
    --PortfolioSize: 75px;
  }
}

@media (max-width: 960px) {
  .Portfolio.r1,
  .Portfolio.r3 {
    width: 80%;
  }
  .Portfolio.r2 {
    width: 100%;
    flex-wrap: wrap;
  }
  .PortfolioItem {
    width: 100%;
    height: 95%;
    box-shadow: 0 0 0;
  }
  .portfolio-item-content {
    display: flex;
    flex-direction: column-reverse;
  }
  .portfolio-description {
    margin-top: 10%;
    width: 100%;
  }
  .portfolio-image {
    width: 100%;
  }
}
