.About > .row-container {
  justify-content: center;
  /* flex-wrap: wrap; */
}

.quoted-for-truth {
  width: 33%;
  flex-wrap: wrap;
  margin-right: 5%;
}

.about-me {
  width: 25%;
  flex-wrap: wrap;
}

.about-me > .column-container {
  width: 100%;
}

.quote,
.profile,
.about-me {
  animation: starfall 1s linear;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  background: rgba(255, 255, 255, 0.1);
  align-items: center;
  margin: 10px;
  padding: 10px;
  box-shadow: 0px 0px 20px rgba(168, 212, 226, 0.3);
}

.blurred-text {
  overflow-wrap: normal;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.1);
  text-shadow: 0 0 0.1px white;
  transition: text-shadow 0.5s linear, color 0.5s linear;
  cursor: default;
  margin: 10px;
}

.about-me:hover > .column-container > .blurred-text,
.about-me:hover > .column-container > .row-container > .blurred-text,
.profile:hover > .blurred-text,
.profile:hover > div > .blurred-text,
.quote:hover > .quote-text {
  cursor: default;
  text-shadow: 0 0 0px lightskyblue;
  color: lightskyblue;
}

.quote > .blurred-text {
  width: 90%;
  font-size: 1.25em;
  text-align: center;
}

.profile-image > img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.column-container:hover > .about-image {
  filter: blur(0px);
}

.quote-text {
  font-size: 0.75em;
}

.about-me {
  animation-delay: 0.7s;
}

.quote {
  animation-delay: 0.5s;
}
.profile {
  animation-delay: 0.3s;
}

@media (max-width: 1200px) {
  .quoted-for-truth {
    width: 50%;
  }
}
@media (max-width: 1080px) {
  .about-me {
    width: 33%;
  }
}

@media (max-width: 840px) {
  .quoted-for-truth {
    width: 80%;
  }
  .about-me {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .About {
    margin-top: 60%;
  }

  .quoted-for-truth {
    width: 90%;
    margin: 0;
  }
  .about-me {
    width: 50%;
  }
  .About > .row-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
