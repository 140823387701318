.SkyOfStars {
  background: url('./static/background.jpg') no-repeat center center fixed;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .SkyOfStars {
    overflow: scroll;
  }
}

body {
  background: black;
  margin: 0px;
}

ul {
  padding: 0;
  margin-top: 1.75vh;
}

.center-star {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.row-container {
  display: flex;
  flex-direction: row;
}
.column-container {
  display: flex;
  flex-direction: column;
}

.justify-around {
  justify-content: center;
  align-items: center;
}

.center-text {
  text-align: center;
}

.border-text {
  color: white;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.no-underline {
  text-decoration: none;
}

.no-italics {
  font-style: normal;
}

@keyframes starfall {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes faded-starfall {
  from {
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
  25% {
    width: 175%;
    height: 175%;
    opacity: 0;
  }
  100% {
    width: 0%;
    height: 0%;
    opacity: 0;
  }
}

@keyframes ripple-l {
  0% {
    width: 75%;
    height: 75%;
    opacity: 1;
  }
  25% {
    width: 400%;
    height: 400%;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/* https://css-tricks.com/snippets/css/typewriter-effect/ */
/* object-fit: contain, width: 100% for images that need to be scaled */
@font-face {
  font-family: 'Century Gothic';
  src: url('./static/CenturyGothic.ttf');
}
